/* eslint-disable no-template-curly-in-string */
const adapterFields = (companyId) => ({
  attachment: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'attachment',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
  },
  sftpAttachment: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'sftp',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    host: {
      label: 'Host',
      value: '',
      hidden: false,
    },
    port: {
      label: 'Port',
      value: '',
      hidden: false,
    },
    username: {
      label: 'Username',
      value: '',
      hidden: false,
    },
    password: {
      label: 'Password',
      value: '',
      hidden: false,
    },
    privateKey: {
      label: 'Private Key',
      value: '',
      hidden: false,
    },
    mode: {
      label: 'Mode',
      value: 'attachment',
      hidden: true,
    },
    attachmentMode: {
      label: 'Attachment Mode',
      value: 'id',
      hidden: false,
      tooltip: 'Possible values: id or displayId',
    },
    attachmentAdapterName: {
      label: 'Attachment Adapter Name',
      value: '',
      hidden: false,
    },
    attachmentsDirectory: {
      label: 'Attachments Directory',
      value: '',
      hidden: false,
      tooltip: 'Please use "/" for root or for example: "/sharedFolder"',
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  sftpData: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'sftp',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    host: {
      label: 'Host',
      value: '',
      hidden: false,
    },
    port: {
      label: 'Port',
      value: '',
      hidden: false,
    },
    username: {
      label: 'Username',
      value: '',
      hidden: false,
    },
    password: {
      label: 'Password',
      value: '',
      hidden: false,
    },
    privateKey: {
      label: 'Private Key',
      value: '',
      hidden: false,
    },
    mode: {
      label: 'Mode',
      value: 'data',
      hidden: true,
    },
    gavitiAdapterName: {
      label: 'Gaviti Adapter Name',
      value: '',
      hidden: false,
    },
    fileNameNotPermanent: {
      label: 'File name not permanent',
      value: undefined,
      hidden: false,
    },
    filePattern: {
      label: 'File pattern',
      value: '_Gaviti',
      hidden: false,
    },
    fileName: {
      label: 'File Name',
      value: '',
      hidden: false,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  ftpAttachment: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'ftp',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    host: {
      label: 'Host',
      value: '',
      hidden: false,
    },
    port: {
      label: 'Port',
      value: '',
      hidden: false,
    },
    user: {
      label: 'Username',
      value: '',
      hidden: false,
    },
    password: {
      label: 'Password',
      value: '',
      hidden: false,
    },
    mode: {
      label: 'Mode',
      value: 'attachment',
      hidden: true,
    },
    attachmentMode: {
      label: 'Attachment Mode',
      value: 'id',
      hidden: false,
      tooltip: 'Possible values: id or displayId',
    },
    attachmentAdapterName: {
      label: 'Attachment Adapter Name',
      value: '',
      hidden: false,
    },
    attachmentsDirectory: {
      label: 'Attachments Directory',
      value: '',
      hidden: false,
      tooltip: 'Please use "/" for (root directory) or for example: "/sharedFolder"',
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  ftpData: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'ftp',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    host: {
      label: 'Host',
      value: '',
      hidden: false,
    },
    port: {
      label: 'Port',
      value: '',
      hidden: false,
    },
    user: {
      label: 'Username',
      value: '',
      hidden: false,
    },
    password: {
      label: 'Password',
      value: '',
      hidden: false,
    },
    mode: {
      label: 'Mode',
      value: 'data',
      hidden: true,
    },
    gavitiAdapterName: {
      label: 'Gaviti Adapter Name',
      value: '',
      hidden: false,
    },
    fileNameNotPermanent: {
      label: 'File name not permanent',
      value: undefined,
      hidden: false,
    },
    filePattern: {
      label: 'File pattern',
      value: '_Gaviti',
      hidden: false,
    },
    fileName: {
      label: 'File Name',
      value: '',
      hidden: false,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  ftpsAttachment: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'ftp',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    host: {
      label: 'Host',
      value: '',
      hidden: false,
    },
    port: {
      label: 'Port',
      value: '',
      hidden: false,
    },
    user: {
      label: 'Username',
      value: '',
      hidden: false,
    },
    password: {
      label: 'Password',
      value: '',
      hidden: false,
    },
    mode: {
      label: 'Mode',
      value: 'attachment',
      hidden: true,
    },
    attachmentMode: {
      label: 'Attachment Mode',
      value: 'id',
      hidden: false,
      tooltip: 'Possible values: id or displayId',
    },
    attachmentAdapterName: {
      label: 'Attachment Adapter Name',
      value: '',
      hidden: false,
    },
    attachmentsDirectory: {
      label: 'Attachments Directory',
      value: '',
      hidden: false,
      tooltip: 'Please use "/" for (root directory) or for example: "/sharedFolder"',
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
    secure: {
      label: 'Secure',
      value: true,
      hidden: true,
    },
  },
  evo: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    code: {
      label: 'Code',
      value: 'evo',
      hidden: true,
    },
    dcn: {
      label: 'DCN',
      value: '',
      hidden: false,
    },
    deviceId: {
      label: 'Device ID',
      value: '',
      hidden: false,
    },
    devicePassword: {
      label: 'Device Password',
      value: '',
      hidden: false,
    },
    setupId: {
      label: 'Setup ID',
      value: '',
      hidden: false,
    },
    appId: {
      label: 'App ID',
      value: 'Gaviti_AR',
      hidden: true,
    },
    themeName: {
      label: 'Theme Name',
      value: 'CustomTheme',
      hidden: true,
    },
    paymentType: {
      label: 'Payment Type',
      value: '',
      hidden: false,
      tooltip: 'CC or ACH',
    },
    paymentAdapter: {
      label: 'Payment Adapter',
      value: true,
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    providerUrl: {
      label: 'Provider URL',
      value: 'https://sandbox.payfabric.com/',
      hidden: false,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
  },
  bluesnap: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    code: {
      label: 'Code',
      value: 'bluesnap',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: true,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    apiCredentialsUsername: {
      label: 'API Credential Username',
      value: '',
      hidden: false,
    },
    apiCredentialsPassword: {
      label: 'API Credential Password',
      value: '',
      hidden: false,
    },
    apiUrl: {
      label: 'API URL',
      value: 'https://sandbox.bluesnap.com/',
      hidden: false,
    },
    paymentType: {
      label: 'Payment Type',
      value: '',
      hidden: false,
    },
    paymentAdapter: {
      label: 'Payment Adapter',
      value: true,
      hidden: true,
    },
    active: {
      label: 'Active',
      value: true,
      hidden: true,
    },
    whiteListBlocks: {
      label: 'White List Blocks',
      value: [],
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
  },
  cwa: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    code: {
      label: 'Code',
      value: 'cwa',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: true,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    privateSecurityKey: {
      label: 'Private Security Key',
      value: '',
      hidden: false,
    },
    publicSecurityKey: {
      label: 'Public Security Key',
      value: '',
      hidden: false,
    },
    apiUrl: {
      label: 'API URL',
      value: 'https://cwamerchantservices.transactiongateway.com/',
      hidden: false,
    },
    paymentType: {
      label: 'Payment Type',
      value: '',
      hidden: false,
    },
    paymentAdapter: {
      label: 'Payment Adapter',
      value: true,
      hidden: true,
    },
    active: {
      label: 'Active',
      value: true,
      hidden: true,
    },
    whiteListBlocks: {
      label: 'White List Blocks',
      value: [],
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
  },
  priority2: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'priority2',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    gavitiJsonAdapterName: {
      label: 'Gaviti JSON Adapter Name',
      value: '',
      hidden: false,
    },
    baseURL: {
      label: 'Base URL',
      value: '',
      hidden: false,
    },
    username: {
      label: 'Username',
      value: '',
      hidden: false,
    },
    password: {
      label: 'Password',
      value: '',
      hidden: false,
    },
    xAppId: {
      label: 'X App ID',
      value: 'APP009',
      hidden: true,
    },
    xAppKey: {
      label: 'X App Key',
      value: '6F4AA9F251E8424AAA93C2B08A0D39EB',
      hidden: true,
    },
    currencyCodeReplaceField: {
      label: 'Currency Code Replace Field',
      value: 'CODE',
      hidden: false,
    },
    invoicesEntities: {
      label: 'Invoice Entities',
      value: ['TFNCITEMS2ONE', 'TFNCITEMS3ONE'],
      hidden: false,
    },
    invoiceQuery: {
      label: 'Invoice Query',
      value: "$select=*&$filter=INVOICEFLAG eq 'Y' and (IVBALANCE gt 0 or IVBALANCE lt 0)",
      hidden: false,
    },
    invoiceFieldForCustomerId: {
      label: 'Invoice Field For Customer Id',
      value: 'ACCNAME',
      hidden: false,
    },
    customerQuery: {
      label: 'Customer Query',
      value: '$select=*',
      hidden: false,
    },
    fetchCustomersOfInvoicesOnly: {
      label: 'Fetch customers of invoices only',
      value: true,
      hidden: false,
      disabled: false,
      tooltip: 'Only fetch customers related to invoices retrieved by the query, not all customers in the company.',
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  qbo: {
    code: {
      label: 'Code',
      value: 'qbo',
      hidden: true,
    },
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    gavitiJsonAdapterName: {
      label: 'JSON Adapter Name',
      value: '',
      hidden: false,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    attachmentSource: {
      label: 'Attachment Source',
      value: 'qbo',
      hidden: false,
    },
    fetchCredits: {
      label: 'Fetch credits',
      value: false,
      hidden: false,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  xero: {
    code: {
      label: 'Code',
      value: 'xero',
      hidden: true,
    },
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    gavitiJsonAdapterName: {
      label: 'JSON Adapter Name',
      value: '',
      hidden: false,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    attachmentSource: {
      label: 'Attachment Source',
      value: 'xero',
      hidden: false,
    },
    fetchCredits: {
      label: 'Fetch credits',
      value: false,
      hidden: false,
    },
    fetchCustomersOfInvoicesOnly: {
      label: 'Fetch customers of invoices only',
      value: true,
      hidden: false,
      disabled: false,
      tooltip: 'Only fetch customers related to invoices retrieved by the query, not all customers in the company.',
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  'zoho-books': {
    code: {
      label: 'Code',
      value: 'zoho-books',
      hidden: true,
    },
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    gavitiJsonAdapterName: {
      label: 'JSON Adapter Name',
      value: '',
      hidden: false,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    organizationId: {
      label: 'Organization ID',
      value: '',
      hidden: false,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    attachmentSource: {
      label: 'Attachment Source',
      value: 'zoho-books',
      hidden: false,
    },
    fetchCredits: {
      label: 'Fetch credits',
      value: false,
      hidden: false,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  qbwc2: {
    code: {
      label: 'Code',
      value: 'qbwc2',
      hidden: true,
    },
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    companyFileLocation: {
      label: 'Company file location',
      value: '',
      placeholder: 'Example: C:\\Documents\\Company\\data.qbw',
      hidden: false,
      tooltip: 'Please enter the path to the .qbw file, for instance: C:\\Documents\\Company\\data.qbw',
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    qbXML: {
      label: 'QB XML',
      value:
        '<?qbxml version="16.0"?><QBXML><QBXMLMsgsRq onError="stopOnError"><CustomerQueryRq><IncludeRetElement>ListID</IncludeRetElement><IncludeRetElement>FullName</IncludeRetElement><IncludeRetElement>FirstName</IncludeRetElement><IncludeRetElement>Email</IncludeRetElement><IncludeRetElement>Phone</IncludeRetElement></CustomerQueryRq><InvoiceQueryRq><PaidStatus>NotPaidOnly</PaidStatus><IncludeRetElement>TxnNumber</IncludeRetElement><IncludeRetElement>RefNumber</IncludeRetElement><IncludeRetElement>BalanceRemaining</IncludeRetElement><IncludeRetElement>Subtotal</IncludeRetElement><IncludeRetElement>CustomerRef</IncludeRetElement><IncludeRetElement>DueDate</IncludeRetElement><IncludeRetElement>TxnDate</IncludeRetElement></InvoiceQueryRq><CreditMemoQueryRq> 		</CreditMemoQueryRq></QBXMLMsgsRq></QBXML>',
      hidden: false,
    },
    gavitiJsonAdapterName: {
      label: 'JSON Adapter Name',
      value: '',
      hidden: false,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    fetchCredits: {
      label: 'Fetch credits',
      value: false,
      hidden: false,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  codat: {
    code: {
      label: 'Code',
      value: 'codat',
      hidden: true,
    },
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    baseURL: {
      label: 'Base URL',
      value: 'https://api.codat.io/',
      hidden: false,
      tooltip: 'For stage you can use https://api-uat.codat.io/',
    },
    gavitiJsonAdapterName: {
      label: 'Gaviti JSON Adapter Name',
      value: '',
      hidden: false,
    },
    codatCompanyId: {
      label: 'Codat Company ID',
      value: '',
      hidden: false,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    fetchCustomers: {
      label: 'Fetch Customers',
      value: true,
      hidden: false,
    },
    customerQuery: {
      label: 'Customer Query',
      value: '',
      hidden: false,
      tooltip: 'If the field is left empty this means all customer data will be fetched',
    },
    fetchInvoices: {
      label: 'Fetch Invoices',
      value: true,
      hidden: false,
    },
    invoiceQuery: {
      label: 'Invoice Query',
      value: 'status=Submitted||status=PartiallyPaid',
      hidden: false,
      tooltip: 'If the field is left empty this means all invoice data will be fetched',
    },
    fetchCredits: {
      label: 'Fetch Credits',
      value: true,
      hidden: false,
    },
    creditsQuery: {
      label: 'Credits Query',
      value: 'status=Submitted',
      hidden: false,
      tooltip: 'If the field is left empty this means all credits data will be fetched',
    },
    fetchCustomersOfInvoicesOnly: {
      label: 'Fetch customers of invoices only',
      value: true,
      hidden: false,
      disabled: false,
      tooltip: 'Only fetch customers related to invoices retrieved by the query, not all customers in the company.',
    },
    attachmentSource: {
      label: 'Attachment Source',
      value: 'codat',
      hidden: false,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  salesforce: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Salesforce',
      value: 'salesforce',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    consumerKey: {
      label: 'Consumer Key',
      value: '',
      hidden: false,
      tooltip: 'Salesforce Consumer Key',
    },
    salesforceUsername: {
      label: 'Salesforce Username',
      value: '',
      hidden: false,
    },
    salesforceAuthServer: {
      label: 'Salesforce Auth Server',
      value: '',
      hidden: false,
      tooltip: 'https://login.salesforce.com or https://test.salesforce.com',
    },
    salesforceCustomerQuery: {
      label: 'Salesforce Customer Query',
      value: '',
      hidden: false,
    },
    salesforceInvoiceQuery: {
      label: 'Salesforce Invoice Query',
      value: '',
      hidden: false,
    },
    gavitiJsonAdapterName: {
      label: 'Gaviti JSON Adapter Name',
      value: '',
      hidden: false,
      tooltip: 'Name of Gaviti JSON Adapter. For example: gaviti-5eb5d4d2e7f47254-json',
    },
    certificationPrivateKey: {
      label: 'Certification Private Key',
      value: '',
      hidden: false,
      tooltip: 'Private Key generated',
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  'net-suite': {
    code: {
      label: 'Code',
      value: 'net-suite',
      hidden: true,
    },
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiJsonAdapterName: {
      label: 'Gaviti JSON Adapter Name',
      value: '',
      hidden: false,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    attachmentSource: {
      label: 'Attachment Source',
      value: 'codat',
      hidden: false,
    },
    fetchCustomersOfInvoicesOnly: {
      label: 'Fetch customers of invoices only',
      value: true,
      hidden: false,
      disabled: false,
      tooltip: 'Only fetch customers related to invoices retrieved by the query, not all customers in the company.',
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
  },
  xlsx: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'xlsx',
      hidden: true,
    },
    customerSheet: {
      label: 'Customers Sheet',
      value: 'Customers',
      hidden: false,
    },
    invoiceSheet: {
      label: 'Invoices Sheet',
      value: 'Invoices',
      hidden: false,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: '',
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
    skipFirstLines: {
      label: 'Skip first rows',
      value: '',
      hidden: false,
    },
  },
  xlsx2: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'xlsx2',
      hidden: true,
    },
    customerSheet: {
      label: 'Customers Sheet',
      value: 'Customers',
      hidden: false,
    },
    invoiceSheet: {
      label: 'Invoices Sheet',
      value: 'Invoices',
      hidden: false,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: '',
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
    skipFirstLines: {
      label: 'Skip first rows',
      value: '',
      hidden: false,
    },
  },
  csv: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'csv',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    delimiter: {
      label: 'Delimiter',
      value: ',',
      hidden: false,
    },
    encoding: {
      label: 'Encoding',
      value: 'utf-8',
      hidden: false,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    textType: {
      label: 'Text Type',
      value: '',
      hidden: false,
      tooltip: 'customers or invoices',
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: '',
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
    skipFirstLines: {
      label: 'Skip first rows',
      value: '',
      hidden: false,
    },
  },
  jsonCodat: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'json',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: {
        customer: {
          id: 'id',
          displayId: 'id',
          displayName: 'customerName',
          emailsLevel_1: 'emailAddress',
          phone: 'phone',
        },
        invoice: {
          id: 'id',
          displayId: 'invoiceNumber',
          customerId: 'customerRef.id',
          createDate: 'issueDate',
          dueDate: 'dueDate',
          totalAmount: 'totalAmount',
          balance: 'amountDue',
          currency: 'currency',
        },
      },
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
  },
  jsonQbo: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'json',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: {
        customer: {
          id: 'Id',
          displayId: 'Id',
          displayName: 'FullyQualifiedName',
          emails: 'PrimaryEmailAddr.Address',
          phone: 'PrimaryPhone.FreeFormNumber',
        },
        invoice: {
          id: 'Id',
          displayId: 'DocNumber',
          customerId: 'CustomerRef.value',
          dueDate: 'DueDate',
          balance: 'Balance',
          localBalance: 'Balance',
          totalAmount: 'TotalAmt',
          localTotalAmount: 'TotalAmt',
          createDate: 'MetaData.CreateTime',
          currency: 'CurrencyRef.value',
          link: 'InvoiceLink',
        },
      },
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
  },
  jsonXero: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'json',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: {
        customer: {
          id: 'ContactID',
          displayId: 'ContactID',
          displayName: 'Name',
          emailsLevel_1: 'EmailAddress',
        },
        invoice: {
          id: 'InvoiceID',
          customerId: 'Contact.ContactID',
          displayId: 'InvoiceNumber',
          totalAmount: 'Total',
          dueDate: 'DueDate',
          createDate: 'Date',
          currency: 'CurrencyCode',
          balance: 'AmountDue',
        },
      },
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
  },
  jsonZohoBooks: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'json',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: {
        customer: {
          id: 'contact_id',
          displayName: 'company_name',
          contactName: 'contact_name',
          emailsLevel_1: 'email',
        },
        invoice: {
          id: 'invoice_id',
          displayId: 'invoice_number',
          totalAmount: 'total',
          dueDate: 'due_date',
          customerId: 'customer_id',
          createDate: 'date',
          currency: 'currency_code',
          balance: 'balance',
        },
      },
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
  },
  jsonQbwc: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'json',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: {
        customer: {
          id: 'ListID.0',
          displayId: 'ListID.0',
          displayName: 'FullName.0',
          contactName: 'FirstName.0',
          emails: 'Email.0',
          phone: 'Phone.0',
        },
        invoice: {
          id: 'TxnNumber.0',
          displayId: 'RefNumber.0',
          balance: 'BalanceRemaining.0',
          totalAmount: 'Subtotal.0',
          customerId: 'CustomerRef.0.ListID.0',
          dueDate: 'DueDate.0',
          createDate: 'TxnDate.0',
        },
      },
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: {
        invoice: [
          {
            field: 'IsPending.0',
            type: 'isNot',
            value: 'true',
          },
        ],
      },
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
  },
  jsonPriority: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'json',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: {
        customer: {
          id: 'CUSTNAME',
          displayId: 'CUSTNAME',
          displayName: 'CUSTDES',
          emailsLevel_1: 'EMAIL',
          phone: 'PHONE',
        },
        invoice: {
          displayId: 'IVNUM',
          customerId: 'ACCNAME',
          createDate: 'CURDATE',
          dueDate: 'FNCDATE',
          totalAmount: 'SUM',
          balance: 'IVBALANCE',
          currency: 'CODE',
          customFields_FNCPATNAME: 'FNCPATNAME',
        },
      },
      hidden: false,
      tooltip: 'FNCPATNAME: Indicate invoice type',
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: {
        invoice: {
          id: "return `${itemRaw['IVNUM']}:${itemRaw['KLINE']}`;",
          CURDATE: "return itemRaw['CURDATE'].substr(0,10);",
          FNCDATE: "return itemRaw['FNCDATE'].substr(0,10);",
        },
      },
      hidden: false,
    },
  },
  jsonNetSuite: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'json',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: {
        customer: {
          contactName: 'Primary Contact',
        },
        invoice: {
          displayId: 'id',
        },
      },
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
  },
  json: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'json',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: '',
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
  },
  xml: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'xml',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: '',
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
  },
  txt: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'txt',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    delimiter: {
      label: 'Delimiter',
      value: ',',
      hidden: false,
    },
    encoding: {
      label: 'Encoding',
      value: 'windows-1255',
      hidden: false,
    },
    textType: {
      label: 'Text Type',
      value: '',
      hidden: false,
      tooltip: 'customers or invoices',
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: '',
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
    skipFirstLines: {
      label: 'Skip first rows',
      value: '',
      hidden: false,
    },
  },
  plaid: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    code: {
      label: 'Code',
      value: 'plaid',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: true,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    accessToken: {
      label: 'Access token',
      value: '',
      hidden: true,
    },
    plaidUrl: {
      label: 'Url',
      value: '',
      hidden: false,
    },
    clientId: {
      label: 'Client Id',
      value: '',
      hidden: true,
    },
    secret: {
      label: 'secret',
      value: '',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: true,
      hidden: true,
    },
    whiteListBlocks: {
      label: 'White List Blocks',
      value: [],
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [7],
      hidden: true,
    },
  },
  stripe: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    code: {
      label: 'Code',
      value: 'stripe',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: true,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    accountId: {
      label: 'Account Id',
      value: '',
      hidden: false,
    },
    paymentType: {
      label: 'Payment Type',
      value: '',
      hidden: false,
    },
    paymentAdapter: {
      label: 'Payment Adapter',
      value: true,
      hidden: true,
    },
    active: {
      label: 'Active',
      value: true,
      hidden: true,
    },
    isExpress: {
      label: 'Is Express',
      value: false,
      hidden: false,
    },
    collectedFees: {
      label: 'Collected Fees',
      value: {},
      hidden: false,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
  },
  universal: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'universal',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    fileContentType: {
      label: 'File Content Type',
      value: 'text/csv',
      hidden: false,
    },
    sheetName: {
      label: 'Sheet Name',
      value: '',
      hidden: false,
    },
    entity: {
      label: 'Entity',
      value: 'bankTransactions',
      hidden: false,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    delimiter: {
      label: 'Delimiter',
      value: ',',
      hidden: false,
    },
    encoding: {
      label: 'Encoding',
      value: 'utf-8',
      hidden: false,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    operation: {
      label: 'Operation',
      value: 'upsert',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    objectFieldsMapping: {
      label: 'Fields Mapping',
      value: '',
      hidden: false,
    },
    objectCalculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
    filterOperators: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    skipAmountOfRows: {
      label: 'Skip first rows',
      value: '',
      hidden: false,
    },
    objectTransformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
  },
  'sage-intacct': {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'sage-intacct',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    gavitiJsonAdapterName: {
      label: 'JSON Adapter Name',
      value: '',
      hidden: false,
    },
    baseURL: {
      label: 'Base URL',
      value: 'https://api.intacct.com/ia/xml/xmlgw.phtml',
      hidden: false,
    },
    sageCompanyId: {
      label: 'Sage Company ID',
      value: '',
      hidden: false,
    },
    isTopLevelEntity: {
      label: 'Top Level Entity',
      value: false,
      hidden: false,
    },
    locationId: {
      label: 'Location ID',
      value: '',
      hidden: false,
    },
    sageUserId: {
      label: 'Sage User ID',
      value: '',
      hidden: false,
    },
    sageUserPassword: {
      label: 'Sage User Password',
      value: '',
      hidden: false,
    },
    attachmentSource: {
      label: 'Attachment Source',
      value: '',
      hidden: false,
    },
    fetchCredits: {
      label: 'Fetch Credits',
      value: true,
      hidden: false,
    },
    fetchCustomers: {
      label: 'Fetch Customers',
      value: true,
      hidden: false,
    },
    fetchInvoices: {
      label: 'Fetch Invoices',
      value: true,
      hidden: false,
    },
    fetchCustomersOfInvoicesOnly: {
      label: 'Fetch customers of invoices only',
      value: true,
      hidden: false,
      disabled: false,
      tooltip: 'Only fetch customers related to invoices retrieved by the query, not all customers in the company.',
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: '',
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
    skipFirstLines: {
      label: 'Skip first rows',
      value: '',
      hidden: false,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
  jsonSageIntacct: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'gaviti',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'push',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    gavitiType: {
      label: 'Gaviti Type',
      value: 'json',
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    customFieldsMapping: {
      label: 'Custom Fields Mapping',
      value: {
        customer: {
          id: 'RECORDNO',
          displayId: 'CUSTOMERID',
          displayName: 'NAME',
          contactName: 'DISPLAYCONTACT.CONTACTNAME',
          phone: 'DISPLAYCONTACT.PHONE1',
          emailsLevel_1: 'DISPLAYCONTACT.EMAIL1',
          emailsLevel_2: 'DISPLAYCONTACT.EMAIL2',
        },
        invoice: {
          id: 'RECORDID',
          displayId: 'RECORDID',
          totalAmount: 'TRX_TOTALENTERED',
          customerId: 'CUSTOMER.RECORDNO',
          dueDate: 'WHENDUE',
          createDate: 'WHENCREATED',
          currency: 'CURRENCY',
          balance: 'TRX_TOTALDUE',
        },
      },
      hidden: false,
    },
    transformations: {
      label: 'Transformations',
      value: '',
      hidden: false,
    },
    filterRowRules: {
      label: 'Filter row rules',
      value: '',
      hidden: false,
      tooltip: 'Values can be comma separated for "Is in", "Is not in" and "Starts with one from list"',
    },
    calculatedFields: {
      label: 'Calculated fields',
      value: '',
      hidden: false,
    },
  },
  sftpCustomFieldsFiles: {
    name: {
      label: 'Name',
      value: '',
      hidden: false,
      tooltip:
        'The adapter name will result in the following pattern: {adapter code}-{adapter type}-{name}-{uuid}, where only name is needed',
    },
    code: {
      label: 'Code',
      value: 'sftp',
      hidden: true,
    },
    type: {
      label: 'Type',
      value: 'pull',
      hidden: true,
    },
    token: {
      label: 'Token',
      value: '',
      hidden: false,
    },
    tokenTTL: {
      label: 'Token TTL',
      value: 1924898400000,
      hidden: true,
    },
    companyId: {
      label: 'Company ID',
      value: companyId,
      hidden: true,
    },
    bucket: {
      label: 'Bucket',
      value: '',
      hidden: true,
    },
    filePathPrefix: {
      label: 'File Path Prefix',
      value: 'prod',
      hidden: true,
    },
    host: {
      label: 'Host',
      value: '',
      hidden: false,
    },
    port: {
      label: 'Port',
      value: '',
      hidden: false,
    },
    username: {
      label: 'Username',
      value: '',
      hidden: false,
    },
    password: {
      label: 'Password',
      value: '',
      hidden: false,
    },
    privateKey: {
      label: 'Private Key',
      value: '',
      hidden: false,
    },
    mode: {
      label: 'Mode',
      value: 'customFieldsFiles',
      hidden: true,
    },
    attachmentMode: {
      label: 'Attachment Mode',
      value: 'id',
      hidden: false,
      tooltip: 'Possible values: id or displayId',
    },
    attachmentAdapterName: {
      label: 'Attachment Adapter Name',
      value: '',
      hidden: false,
    },
    attachmentsDirectory: {
      label: 'Attachments Directory',
      value: '',
      hidden: false,
      tooltip: 'Please use "/" for root or for example: "/sharedFolder"',
    },
    active: {
      label: 'Active',
      value: false,
      hidden: true,
    },
    jobSettings: {
      label: 'Error Recipients',
      value: '',
      hidden: false,
      tooltip: 'Add additional unique emails separated by commas',
    },
    schedules: {
      label: 'Schedules',
      value: [6, 13, 19],
      hidden: false,
    },
  },
});

export default adapterFields;
